<template>
  <div :class="{ 'md:pt-20 pt-10': be604ukQuickWinsRunning }" class="merchants" data-test="merchants-section">
    <h2 class="merchants__title">
      {{ $i18n('templates.merchants-at-airport', { location: airport.name }) }}
    </h2>
    <div class="merchants__list-wrapper" id="locations_merchants">
      <div
        v-for="merchant in merchants"
        :key="merchant.id"
        class="merchants__card"
        data-test="merchants-card"
      >
        <a
          :class="{ 'flex flex-col h-full': be604ukQuickWinsRunning }"
          :href="formatUrl(merchant.slug)"
          class="merchants__card-link"
          data-test="merchant-card-link"
        >
          <div class="merchants__card__image-wrapper">
            <div
              class="merchants__card__image-wrapper__bg"
              :style="{ 'background-image': 'url(' + merchant.logo + ')' }"
            ></div>
          </div>
          <div class="merchants__card-separator" />
          <div
            :class="{ 'mb-5': be604ukQuickWinsRunning }"
            class="merchants__card__name"
          >
            {{ merchant.name }}
          </div>
          <experiment-toggle name="airUkQuickWins">
            <template #a>
              <div
                v-if="formatPrice(merchant.from_price)"
                class="merchants__card__price"
              >
                {{
                  $i18n('templates.from-x-euro-week', {
                    amount: formatPrice(merchant.from_price),
                  })
                }}
              </div>
            </template>
            <template #b>
              <div
                v-if="formatPrice(merchant.from_price)"
                class="mt-auto py-3 rounded-[4px] border w-full text-center font-bold border-orange-500 hover:bg-orange-500 text-orange-500 hover:text-white"
              >
                {{
                  $i18n('templates.from-x-euro-week', {
                    amount: formatPrice(merchant.from_price),
                  })
                }}
              </div>
            </template>
          </experiment-toggle>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ExperimentToggle from '~/components/experiments/ExperimentToggle.vue';

export default {
  components: {
    ExperimentToggle,
  },
  data() {
    return {
      be604ukQuickWinsRunning: false,
    };
  },
  computed: {
    merchants() {
      return this.$store.state.parkings;
    },
    language() {
      return this.$store.state.language;
    },
    airport() {
      return this.$store.state.airport;
    },
  },
  created() {
    this.be604ukQuickWinsRunning =
      this.$experimentVariant('airUkQuickWins') === 'b';
  },
  methods: {
    formatPrice(fromPrice) {
      if (!fromPrice || +fromPrice === 0) {
        return undefined;
      }

      return new Intl.NumberFormat(this.language.lang, {
        style: 'currency',
        currency: this.language.currency.iso_code ?? 'EUR',
      }).format(fromPrice);
    },
    formatUrl(slug) {
      return `/${this.airport.slug}/${slug}.html`;
    },
  },
};
</script>

<style lang="scss">
.merchants {
  &__title {
    @apply text-base md:text-xl mb-8 font-cocogoose;
  }

  &__list-wrapper {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-10 md:grid-cols-3 md:gap-12 lg:gap-7.5 lg:grid-cols-4;
  }

  &__card {
    @apply flex flex-col rounded-md shadow-card bg-white p-5;

    &-separator {
      @apply shadow-sepeartor -mx-5 h-px;
    }

    $self: &;
    &:hover {
      #{$self}__name {
        @apply text-blue-600;
      }
    }

    &__name {
      @apply font-bold text-black mt-3 overflow-ellipsis overflow-hidden text-md md:text-15;
    }

    &__price {
      @apply font-sans text-gray-400 md:text-15 text-sm;
    }

    &-link {
      &:hover {
        @apply no-underline;
      }
    }

    &__image-wrapper {
      @apply flex place-content-center mb-5 w-auto h-52 sm:w-72 sm:h-44 md:w-60 md:h-32 lg:w-56 lg:h-28;
      &__bg {
        @apply object-scale-down bg-no-repeat bg-contain aspect-[16/16];
        background-position: center;
      }
    }
  }
}
</style>
